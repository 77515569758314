<template>
  <div>
    <b-card no-body class="slide-card">
      <div class="text-center overflow-hidden">
        <b-img-lazy v-bind="mainProps" :src="handlerImageShow(item.gorsel)" alt="Test" height="100" />
        <div class="slide-action d-flex">
          <div class="slide-trash">
            <i class="fad fa-trash" @click="handlerRemove(item.k_no)" />
          </div>
          <div class="slide-cogs">
            <i class="fad fa-cog text-dark" @click="handlerUpdate(item)" />
          </div>
        </div>
        <div class="slide-body" v-if="item.icerik[defaultDil].baslik.length > 0">
          <h6 class="d-block">{{ item.icerik[defaultDil].baslik }}</h6>
          <p class="d-block">{{ item.icerik[defaultDil].aciklama }}</p>
        </div>

        <div class="slide-footer d-flex justify-content-between align-items-center">
          <div class="slide-footer-left"></div>
          <div class="slide-footer-right">
            <span class="bg-success text-light" v-for="(lang, index) in item.icerik" :key="index">{{ index }}</span>
          </div>
        </div>
      </div>
    </b-card>

    <SlideGuncelle :updateData="updateData" :openModal="openModal" @closeModal="closeModal = $event" />
  </div>
</template>

<script>
import store from '@/store';
import { defineComponent, ref, watch } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';
import Swal from 'sweetalert2';
import SlideGuncelle from './slideGuncelle.vue';
export default defineComponent({
  components: { SlideGuncelle },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const expo = {};
    const toast = useToast();
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.openModal = ref(false);
    expo.closeModal = ref(false);
    expo.updateData = ref({});
    expo.mainProps = ref({
      center: true,
      fluidGrow: true,
      blank: true,
      blankColor: '#bbb',
      thumbnail: true,
      show: true,
    });
    expo.handlerImageShow = (val) => {
      if (val != undefined) {
        const fileName = store.getters.getUserFirma.db;
        const base_url =
          process.env.NODE_ENV === 'development'
            ? process.env.VUE_APP_DEV_BASEURL.slice(0, -3)
            : process.env.VUE_APP_PRO_BASEURL.slice(0, -3);
        const url = `${base_url}uploads/${fileName}/slide/${val}`;
        return url;
      }
    };
    expo.handlerUpdate = (event) => {
      expo.updateData.value = event;
      expo.openModal.value = true;
    };
    expo.handlerRemove = (k_no) => {
      Swal.fire({
        title: 'Uyarı',
        text: 'Kayit Silinecektir',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          store.dispatch('slideSil', k_no).then((res) => {
            if (res.data.success == true) {
              toast.error('Görsel Silme Başarılı.', { position: 'bottom-left' });
            }
          });
        }
      });
    };

    watch(expo.closeModal, (val) => {
      if (val) {
        expo.openModal.value = false;
        expo.closeModal.value = false;
        expo.updateData.value = {};
      }
    });
    return { ...expo };
  },
});
</script>

<style lang="scss" scoped>
.slide-card {
  position: relative;
  width: 100%;
  max-height: 200px;
  overflow: hidden;
  border-radius: 5px;
  .slide-action {
    padding: 5px;
    position: absolute;
    top: 5px;
    right: 0px;
    z-index: 2;
    .action-button {
      padding: 5px;
      background-color: #eeeeee;
      border-radius: 5px;
      margin-right: 5px;
      transition: all 0.2s ease-in-out;
      cursor: pointer;
    }
    .slide-trash {
      @extend .action-button;
      color: red;
      &:hover {
        transition: all 0.3s ease-in-out;
        background-color: #a1a1a1;
      }
    }
    .slide-cogs {
      @extend .action-button;
      &:hover {
        transition: all 0.2s ease-in-out;
        background-color: #a1a1a1;
      }
    }
    i {
      font-size: 22px;
    }
  }
  .slide-body {
    position: absolute;
    display: block;
    text-align: start;
    padding: 5px;
    height: 55px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 100%;
    background: linear-gradient(270deg, rgba(244, 244, 244, 0.1) 0%, rgba(222, 222, 222, 0.7) 100%);
    color: #fff;
  }

  .slide-footer {
    position: absolute;
    width: 100%;
    bottom: 10px;
    .slide-footer-right {
      padding-right: 5px;
      span {
        background: #eee;
        padding: 5px;
        margin-right: 5px;
        font-size: 12px;
      }
    }
  }
}
// .card-developer-meetup {
//   .meetup-img-wrapper {
//     background-color: rgba(#ddd, 0.1);
//   }
//   .meetup-header {
//     margin-bottom: 2rem;

//     .meetup-day {
//       text-align: center;
//       border-right: 1px solid #dedede;
//       padding-right: 1.3rem;
//       margin-right: 1.3rem;
//     }
//   }
//   .media {
//     .avatar .avatar-content {
//       width: 34px;
//       height: 34px;
//     }
//     & + .media {
//       margin-top: 1.2rem;
//     }
//   }
//   .avatar-group {
//     margin-top: 2rem;
//   }
// }
</style>
